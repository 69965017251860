@import "@scss/theme/common.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "admin-lte/build/scss/adminlte";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@scss/components/_breadcrumb.scss";
@import "@scss/components/buttons.scss";
@import "@scss/components/pagination.scss";

$mainColor: #395B64;
.container-custom {
  min-height: 100vh;
}

.form-control {
  appearance: auto !important;
}

.fileBlock {
  display:none;
}

.programBlock {
  display:none;
}

.required::after {
  margin-left: 5px;
  content:"*";
  color:red;
}

.sidebar-overflow {
  overflow-y: revert !important;
}

.reportInfo {
  display: none;
}

.main-text_color {
  color: $mainColor;
}

.footer-block {
  background-color: rgba(0, 0, 0, 0.025);
}

.main-text-seo {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #395B64;
  font-weight: 600;
}