$mainColor: #395B64;
$secondColor: #C0D9E0;

.pagination {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 5px;

  .pagination__item {
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .pagination__item .pagination__link {
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
    color: $mainColor
  }

  .pagination__item.active .pagination__link,
  .pagination__item:hover .pagination__link {
    background-color: $mainColor;
    color: white;
  }

  .pagination__item.disabled .pagination__link, {
    background-color: white;
    color: $mainColor;
  }

  .pagination__item.active {
    background-color: $mainColor;
    color: white;
  }

  /* Медиазапрос для адаптивности */
  @media (max-width: 768px) {
    .pagination__item {
      width: 32px;
      height: 32px;
    }

    .pagination__item .pagination__link {
      line-height: 32px;
    }
  }

  .pagination__link {
    border: none;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
}